<template>
  <section id="experience">
    <div class="container">
      <div class="exp-top row">
        <div class="col-12 col-md-5">
          <p class="h3 exp-title">
            Ne vous sentez pas obligé de regarder cette vidéo…
          </p>
          <span class="video-player-icon d-flex js-vid-open">
            <img :src="PlaySVG" class="js-svg" />
          </span>
        </div>
        <div class="exp-desc col-12 offset-md-1 col-md-6">
          <p>
            Le plus simple pour comprendre ce qu'est un Smart Group, c'est d'y
            participer.
          </p>
          <p>
            Vous découvrirez alors la manière intuitive, vivante et immédiate de
            créer une réelle interrelation entre les personnes et pour les
            projets, que ce soit dans les domaines économiques, sociaux ou
            culturels.
          </p>
          <p>
            Expérimentez un processus unique, nourrissez-le et faites-le
            fructifier pour le mettre à votre service, au service des autres et
            des projets !<br />Vivez Smart Group en entreprise et dans tous
            types d’organisations.
          </p>
          <p>
            Contactez un intervenant pour rencontrer ou créer un Smart Group
          </p>
          <a href="#intervenant" class="btn btn-primary">
            Contactez un intervenant
          </a>
        </div>
      </div>
    </div>
    <img :src="LogoOutlineSVG" class="experience-logo-outlined logo-outlined" />
    <div class="container" id="concept">
      <div class="h2">
        <h2>Concept</h2>
        <CommonCircles />
      </div>
      <div class="exp-concept-intro">
        <p>
          <i
            >"En introduisant dans un système une infime quantité de ce qui lui
            est contraire, on renforce singulièrement sa nature propre, sa
            capacité à évoluer, à se développer et à mûrir. Ce processus est
            complet quand il se réalise au profit de toutes les parties qui le
            composent et de toutes celles avec lesquelles il entre en
            interrelation"</i
          >
        </p>
        <p><strong>Smart Group © c’est simple.</strong></p>
        <p>
          Un Smart Group, c’est la stimulation positive de tous les processus
          de relation, de collaboration, de créativité, de fonctionnement, de
          production et d’amélioration continue des organisations humaines, que
          ce soit dans tous les domaines de la vie, dans les champs économiques,
          sociaux ou culturels.<br />
          Un Smart Group c’est un espace de Sagesse Collective où de nouveaux
          modes de collaboration et de contribution peuvent être expérimentés
          par ses membres.
        </p>
        <p>
          Le Smart Group est toujours au service de l’organisation qui a permis
          son existence.<br />
          Il est à son service et au service de toutes les personnes qui sont
          liées à cette organisation, pas uniquement au service de celles qui
          participent au Smart Group.<br />
          Pour cela le Smart Group peut proposer ses propres projets ou bien
          répondre à des demandes de l’organisation qui l’a ouvert.<br />
          Il n’est pas un lieu de décision mais de proposition. Il ne prend
          pas en œuvre de réalisations concrètes mais veille à créer les
          conditions matérielles et immatérielles qui faciliteront la mise
          en œuvre.
        </p>
        <p>Un Smart Group travaille sur des actions concrètes.</p>
        <p>
          Un Smart Group est composé de membres volontaires (entre 3 et 12
          personnes) accompagné par d’un intervenant ou une intervenante.<br />
          Les membres ont eu connaissance de la création d’un Smart Group ont
          été invités à une première participation, puis ont souhaité participer.
          <br />A partir de la seconde participation on est donc membre du
          Smart Group jusqu’au moment où on peut tout aussi librement le quitter.
        </p>
        <p>
          Le Smart Group est créé autour d’un intervenant extérieur formé et
          certifié par Smart Group©.<br />Chaque groupe peut choisir sont
          appellation propre.
        </p>
      </div>
    </div>
    <div class="container" id="experiences-vivantes">
      <div class="h2">
        <h2>Nos expériences vivantes</h2>
        <CommonCircles />
      </div>
      <div class="exp-intro">
        <p>
          Smart Group est le fruit d’un itinéraire de plus de quinze années
          à la rencontre de nombreuses organisations, dans tous les secteurs
          de la société et des êtres humains qui les composent.<br />Il est
          le fruit d’un constat et d’une intention : la vie active est de plus
          en plus source de souffrance pour les êtres humains d’aujourd’hui,
          alors tentons d’apporter de manière simple et vivante un processus
          qui nourrit immédiatement toutes les dimensions humaines.
        </p>
        <p>
          Smart Group permet de réconcilier le mouvement individuel avec le
          collectif, la liberté d’être avec la nécessité de respecter les
          structures, l’épanouissement humain avec le réalisme économique.
          <br />Smart Group, ce n’est pas une technique ou une méthode,
          c’est un processus vivant, c’est une vision pour l’avenir, c’est
          le cadeau que l’on peut faire aux générations qui viennent après nous.
        </p>
        <p>
          Pour vous faire une idée plus concrète, consultez les références et
          les témoignages des expériences vivantes des Smart Group.<br />
          Ils vous montreront la diversité, la richesse et la pertinence des
          réalités toutes singulières issues d’un même processus.
        </p>
        <p>
          Et surtout, contacter une intervenante ou un intervenant pour entrer
          de manière vivante en contact avec cette méthode.
        </p>
      </div>
      <CommonOrganizationsSlider />
    </div>
  </section>
</template>

<script setup lang="ts">
import LogoOutlineSVG from "@/assets/front/icon/logo-outline.svg";
import CommonCircles from "@/components/common/CommonCircles.vue";
import PlaySVG from "@/assets/front/icon/play.svg";
import CommonOrganizationsSlider from "@/components/common/CommonOrganizationsSlider.vue";
import SVGInject from "@iconfu/svg-inject";
import { onMounted } from "vue";

onMounted(() => {
  // SVG Inject
  SVGInject(document.getElementsByClassName("js-svg"));
});
</script>

<style scoped lang="scss">
// Passer les couleurs en dynamique
.video-player-icon {
  width: 48px;
  height: 48px;
  border: solid 1px #101288;
  background-color: #101288;
  border-radius: 100%;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  margin: 0 auto 30px;
  float: right;

  &:hover {
    transition: 0.2s;
    background-color: #fff;
    cursor: pointer;

    path {
      fill: black;
    }
  }
}
</style>
<style>
.video-player-icon path {
  fill: #fff !important;
}

.video-player-icon:hover path {
  fill: #101288 !important;
}
</style>
