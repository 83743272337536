import type { CRUDOptions, URLParameter } from "@/types/api";
import { type StoreDefinition, defineStore } from "pinia";
import {
  deleteAPIData,
  fetchAPI,
  fetchAPIMembers,
  patchAPIData,
  sendAPIData,
} from "./api";

/**
 * Define a Pinia Store for CRUD specific entities, This store is bundled with
 * existing actions such as:
 *
 * - get: Get a single element by id
 * - all: Get all elements
 * - post: Post data to the element
 * - patch: Patch data to the element
 *
 * This store is for "actions only" manipulations that give direct
 * Promises responses. Do not use state and getters in it.
 *
 * @param route The API route to the collection (e.g **\/users**)
 * @param options The options to pass to the store (If you need to add other actions)
 * @returns StoreDefinition
 */
export function defineCRUDStore<T>(
  route: string,
  options?: CRUDOptions
): StoreDefinition {
  return defineStore(route, {
    state: options?.state,
    getters: options?.getters,
    actions: {
      ...generateCRUDActions<T>(route),
      ...options?.actions,
    },
  } as any);
}

export function generateCRUDActions<T>(route: string) {
  return {
    async post(data: any): Promise<T> {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await sendAPIData(data, route);
          if (response.status === 201) resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async patch(id: number, data: any): Promise<T> {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await patchAPIData(data, `${route}/${id}`);
          if (response.status === 200) resolve(response.data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async get(id: number): Promise<T> {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetchAPI<T>(`${route}/${id}`);
          resolve(response);
        } catch (error: any) {
          reject(error);
        }
      });
    },
    async remove(id: number) {
      deleteAPIData(`${route}/${id}`);
    },
    async all(params?: Array<URLParameter>): Promise<T[]> {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetchAPIMembers<T[]>(
            route,
            stringify_parameters(params)
          );
          resolve(response);
        } catch (error: any) {
          reject(error);
        }
      });
    },
  };
}

export function stringify_parameters(parameters?: Array<URLParameter>): string {
  if (parameters)
    return "?" + parameters.map((p) => `${p.key}=${p.value}`).join("&");

  return "";
}
