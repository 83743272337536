import { defineStore } from "pinia";
import {
  APIResponseGetOrganizaton,
  APIResponseGetPublicHome,
} from "@/types/api";
import axios from "axios";

type PublicAPIState = {
  home?: APIResponseGetPublicHome;
  organization?: APIResponseGetOrganizaton;
  fetching: boolean;
};

export const useAPIPublic = defineStore("public", {
  state: (): PublicAPIState => ({
    home: undefined,
    organization: undefined,
    fetching: false,
  }),
  actions: {
    async fetchHome() {
      const api_url = import.meta.env.VITE_API_URL;
      this.fetching = true;
      const response = await axios.get(`${api_url}/public/home`);
      this.home = response.data;
      this.fetching = false;
    },
    async fetchOrganization(name: string) {
      const api_url = import.meta.env.VITE_API_URL;
      this.fetching = true;
      const response = await axios.get(`${api_url}/public/organization`, {
        params: { slug: name },
      });
      this.organization = response.data;
      this.fetching = false;
    },
  },
});
