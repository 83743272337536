<script setup lang="ts">
import { onMounted } from "vue";
import { useApplication } from "@/store/api/application";
import CommonFooter from "@/components/common/CommonFooter.vue";
import CommonHeader from "@/components/common/CommonHeader.vue";
import $ from "jquery";
import { useRoute } from "vue-router";

const application_store = useApplication();

onMounted(() => {
  application_store.setMode("FRONT");
});

const emits = defineEmits(["categoryClick"]);
const route = useRoute();

function gotoCategory(category: string) {
  if (!category) return;
  if (route.name != "home") {
    setTimeout(() => {
      const target = $(category);
      const offset = target.offset();

      if (offset) {
        $("html, body").animate({ scrollTop: offset.top }, 500);
      }
    }, 300);
  } else {
    const target = $(category);
    const offset = target.offset();

    if (offset) {
      $("html, body").animate({ scrollTop: offset.top }, 500);
    }
  }
}
</script>

<template>
  <CommonHeader @category-click="gotoCategory" />
  <div class="main-content">
    <slot />
  </div>
  <CommonFooter />
</template>

<style scoped>
.main-content {
  min-height: 100vh;
}
</style>
