export const roles = [
  { id: "ROLE_USER", name: "Invité.e" },
  { id: "ROLE_MEMBER", name: "Membre" },
  { id: "ROLE_SPEAKER", name: "Intervenant.e" },
  { id: "ROLE_TRAINER", name: "Formateur" },
  { id: "ROLE_ADMIN", name: "Gestionnaire" },
];

export function getRoleNameById(id: string): string | undefined {
  const role = roles.find((v) => v.id === id);
  return role?.name;
}

export function getRoleById(
  id: string
): { id: string; name: string } | undefined {
  return roles.find((v) => v.id === id);
}

export function getArrayByString(str: string): Array<string | undefined> {
  const roles = str.split(",");
  const names = roles.map((v) => getRoleNameById(v));
  return names;
}

export function getHighestRole(user_roles: string[]): Roles {
  for (let i = roles.length - 1; i > 0; i--) {
    if (user_roles.includes(roles[i].id)) return i;
  }

  return Roles.ROLE_USER;
}

export enum Roles {
  ROLE_USER,
  ROLE_MEMBER,
  ROLE_SPEAKER,
  ROLE_TRAINER,
  ROLE_ADMIN,
}
