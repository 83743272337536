<template>
  <div class="card-inter-list slider-fade">
    <CommonSpeakerCard
      v-for="speaker in public_api.home?.speakers"
      :key="speaker.email"
      :speaker="speaker"
    />
  </div>
  <div class="slider-fade-arrows card-inter-arrows">
    <div class="btn btn-primary arrow-prev">
      <img :src="ChevronLeftSVG" alt="chevron-left" class="js-svg" />
    </div>
    <div class="btn btn-primary arrow-next">
      <img :src="ChevronRightSVG" alt="chevron-right" class="js-svg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonSpeakerCard from "@/components/common/CommonSpeakerCard.vue";
import ChevronLeftSVG from "@/assets/front/icon/chevron-left.svg";
import ChevronRightSVG from "@/assets/front/icon/chevron-right.svg";
import { onBeforeUnmount, onMounted } from "vue";
import $ from "jquery";
import { useAPIPublic } from "@/store/api/public";

const public_api = useAPIPublic();

onBeforeUnmount(() => {
  $(".card-inter-list").slick("unslick");
});

onMounted(() => {
  // Intervenants
  $(".card-inter-list").slick({
    infinite: true,
    arrows: true,
    speed: 0,
    dots: false,
    rows: 2,
    slidesPerRow: 1,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "linear",
    draggable: false,
    focusOnSelect: false,
    swipe: false,
    prevArrow: $(".card-inter-arrows .arrow-prev"),
    nextArrow: $(".card-inter-arrows .arrow-next"),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
</script>
