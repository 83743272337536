import { createApp } from "vue";
import { createPinia } from "pinia";
import { createVuestic } from "vuestic-ui";

import globalconfig from "@/services/vuestic-ui/global-config";
import App from "./App.vue";
import router from "./router";
import "vuestic-ui/css";
import "@/services/vuestic-ui/css/overrides.css";
import "@/services/smartgroup/css/style.scss";
import { useAPI } from "./store/api/api";
import Cookies from "js-cookie";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(
  createVuestic({
    config: globalconfig,
  })
);

const api = useAPI();
api.$subscribe((_, state) => {
  Cookies.set("accessToken", state.jwt, { expires: 12 });
  Cookies.set("user", state.user_id.toString(), { expires: 12 });
});

app.mount("#app");
