import type {
  APIResponseStatus,
  APIStore,
  APIResponseAuth,
  APIResponseError,
  APIGenericError,
} from "@/types/api";
import axios, {
  AxiosError,
  type AxiosResponse,
  type AxiosRequestTransformer,
} from "axios";
import { defineStore, type StoreDefinition } from "pinia";
import Cookies from "js-cookie";

interface APIEntityResponse<T> extends Array<T> {
  membersCount: number;
}

/**
 * Fetch an API route for data. Precise a generic type to make
 * a cleaner data type retreive.
 *
 * This function will use VITE_API_URL env variable before the actual route.
 *
 * @param route API route to query on
 * @returns The type of the generic precised.
 */
export function fetchAPI<T>(route: string, params?: string): Promise<T> {
  return new Promise<T>(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      const api = useAPI();
      let url = `${api_url}${route}`;
      if (params) {
        url += params;
      }
      const data = await (
        await axios.get(url, {
          headers: {
            Authorization: `Bearer ${api.getToken}`,
          },
        })
      ).data;
      resolve(data as unknown as T);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Fetch an API route for data. Precise a generic type to make
 * a cleaner data type retreive.
 *
 * This function will use VITE_API_URL env variable before the actual route.
 *
 * @param route API route to query on
 * @returns The type of the generic precised.
 */
export function fetchAPIMembers<T>(route: string, params?: string): Promise<T> {
  const api = useAPI();
  return new Promise<T>(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      let url = `${api_url}${route}`;
      if (params) {
        url += params;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${api.getToken}`,
        },
      });

      const data = await response.data["hydra:member"];
      data.membersCount = await response.data["hydra:totalItems"];
      resolve(data as unknown as T);
    } catch (error) {
      reject(error);
    }
  });
}

export function sendAPIData(data: any, route: string): Promise<AxiosResponse> {
  const api = useAPI();
  return new Promise(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.post(`${api_url}${route}`, data, {
        headers: {
          "Content-Type": "application/ld+json",
          Authorization: `Bearer ${api.getToken}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Improved sendAPIData function with promises
 * POST to smartgroup API
 * @param data data to pass to the post request
 * @param route API route
 */
export function postAPIData<T, R>(
  data: T,
  route: string
): Promise<AxiosResponse<R, APIGenericError>> {
  const api = useAPI();
  const api_url = import.meta.env.VITE_API_URL;
  return axios.post<R>(`${api_url}${route}`, data, {
    headers: {
      "Content-Type": "application/ld+json",
      Authorization: `Bearer ${api.getToken}`,
    },
  });
}

export function patchAPIData(
  request_data: any,
  route: string
): Promise<AxiosResponse> {
  const api = useAPI();
  return new Promise(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.patch(`${api_url}${route}`, request_data, {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Authorization: `Bearer ${api.getToken}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteAPIData(route: string): Promise<AxiosResponse> {
  const api = useAPI();
  return new Promise(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.delete(`${api_url}${route}`, {
        headers: {
          Authorization: `Bearer ${api.getToken}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * Fetch the / route of the API platform, and return an **APIResponseStatus** object
 * that contains informations about the response.
 *
 * This function will use VITE_API_URL env variable before the actual route.
 *
 * @returns {APIResponseStatus} Response
 */
function fetchAPIStatus(): Promise<APIResponseStatus> {
  return new Promise((resolve, reject) => {
    const api_url = import.meta.env.VITE_API_URL;
    axios.get(`${api_url}/docs`).then((response) => {
      if (response.status === 200) {
        resolve({
          status: true,
        });
      }

      reject({ code: response.status, message: "Couldn't connect to API" });
    });
  });
}

/**
 * POST to user autentication api route
 * Will return an APIResponseAuth that have the token
 *
 * @returns {APIResponseAuth} Response
 */
function postAuthentication(
  user: string,
  password: string
): Promise<APIResponseAuth> {
  return new Promise(async (resolve, reject) => {
    try {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.post(`${api_url}/authentication_token`, {
        email: user,
        password: password,
      });

      resolve({
        code: response.data.code,
        user_id: response.data.user_id,
        user_email: response.data.user_email,
        payload: {
          token: response.data.payload.token,
        },
      });
    } catch (error: AxiosError | any) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status == 401) {
          reject("Identifiants invalides.");
        }
        reject("Une erreur s'est produite.");
      }
      reject("Une erreur s'est produite.");
    }
  });
}

/**
 * **SMART GROUP API STORE**:
 * Use this store to retreive data from the website API,
 * do not forget to fetch the route before performing anything
 * in the component to be sure your data is up to date before mounting
 * the component.
 */
export const useAPI = defineStore("api", {
  state: () => {
    const api_token = Cookies.get("accessToken") || "";
    const user_id = Cookies.get("user") || "";
    return {
      jwt: api_token,
      user_id: user_id.length ? parseInt(user_id) : null,
      status: false,
      error: false,
      error_message: "",
    } as APIStore;
  },
  getters: {
    getStatus: (state) => state.status,
    getError: (state) => state.error,
    getErrorMessage: (state) => state.error_message,
    getToken: (state) => state.jwt,
  },
  actions: {
    async fetchStatus() {
      try {
        const response = await fetchAPIStatus();
        this.status = response.status;
      } catch (error: any) {
        this.error = true;
      }
    },
    async authenticate(
      user: string,
      password: string
    ): Promise<APIResponseAuth> {
      return new Promise((resolve, reject) => {
        postAuthentication(user, password)
          .then((response) => {
            this.jwt = response.payload.token;
            this.user_id = response.user_id;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async isAuthenticated() {
      if (this.jwt && this.jwt !== "") {
        try {
          await fetchAPI("/users");
          return true;
        } catch (error) {
          return false;
        }
      }
      return false;
    },
    logoutUser() {
      this.jwt = "";
    },
  },
});
