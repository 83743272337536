<template>
  <div>
    <VaModal v-model="modal_open" close-button hide-default-actions>
      <h4>{{ speaker.firstname }} {{ speaker.lastname }}</h4>
      <p>
        Intervenant depuis : <b>{{ getDateString(speaker.speakerSince) }}</b>
      </p>
      <p>
        Statut de l'intervenant : <b>{{ speaker.status }}</b>
      </p>
      <p>
        Membre Smart Group depuis :
        <b>{{ getDateString(speaker.memberSince) }}</b>
      </p>
      <p v-if="speaker.localization">
        Localisation : <b>{{ speaker.localization }}</b>
      </p>
      <p v-if="speaker.radius">
        Rayon d'intervention : <b>{{ speaker.radius }}</b>
      </p>
      <p v-if="speaker.age">
        Age : <b>{{ speaker.age }}</b>
      </p>
      <a :href="`mailto:${speaker.email};`" class="btn btn-primary">Contact</a>
    </VaModal>
    <div class="card card-inter">
      <div class="card-top">
        <div class="card-top-img">
          <img
            v-if="speaker.avatar"
            :src="speaker.avatar"
            :alt="`${speaker.firstname} ${speaker.lastname}`"
          />
          <img v-else :src="IconSVG" />
        </div>
        <div class="card-top-plus" @click="() => (modal_open = true)">
          <div class="btn-plus">+</div>
        </div>
      </div>
      <div class="card-body">
        <p class="h4 m-0">{{ speaker.firstname }}</p>
        <p class="txt-upper h4 lastname m-0">{{ speaker.lastname }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getDateString, parseDate } from "@/services/smartgroup/date";
import IconSVG from "@/assets/front/icon/icon-smartgroup.svg";
import { PublicSpeaker } from "@/types/api";
import { ref } from "vue";

const modal_open = ref(false);

defineProps<{
  speaker: PublicSpeaker;
}>();
</script>

<style scoped>
.lastname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
