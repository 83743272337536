<script setup lang="ts">
import Logo from "@/components/images/logo.vue";
import { onMounted } from "vue";
import $ from "jquery";

const navigation_items = [
  { to: "#concept", label: "Le concept" },
  { to: "#experiences-vivantes", label: "Expériences" },
  { to: "#intervenant", label: "Invervenants" },
  { to: "#formation", label: "Formation" },
];

const emits = defineEmits(["categoryClick"]);

const mailto =
  "mailto:bernard.maufras@smart-group.fr?subject=Demande d'information smart-group&body=Merci de préciser vos Nom - Prénom - Ville et Département afin que nous puissions répondre de manière pertinente à votre demande.";

onMounted(() => {
  //Burger Menu
  $(".js-menu-open").on("click", () => {
    if ($(".js-menu-open").hasClass("menu-opened")) {
      closeMenuBurger();
    } else {
      openMenuBurger();
    }
  });

  $(".burger-menu").on("click", () => {
    closeMenuBurger();
  });

  const page_window = $(window);
  const window_width = page_window.width();
  if (window_width) {
    if (window_width < 992) {
      $(".menu__content a").on("click", function () {
        closeMenuBurger();
      });
    }

    $(window).resize(function () {
      if (window_width >= 992) {
        $(".menu").css("opacity", "1");
      }
    });
  }
});

function openMenuBurger() {
  $(".js-menu-open").addClass("menu-opened");
  $(".menu").toggleClass("d-none");
  $(".menu").animate(
    {
      opacity: 1,
    },
    200
  );
  $("body").toggleClass("modal-open");
}

function closeMenuBurger() {
  $(".js-menu-open").removeClass("menu-opened");
  $(".menu").animate(
    {
      opacity: 0,
    },
    200,
    function () {
      $(".menu").toggleClass("d-none");
    }
  );
  $("body").toggleClass("modal-open");
}
</script>

<template>
  <header>
    <div class="container">
      <div
        class="header-content d-flex justify-content-between align-items-center"
      >
        <RouterLink to="/" class="menu-logo">
          <Logo />
        </RouterLink>
        <div class="menu d-none d-lg-block">
          <div class="menu__content">
            <ul class="list-unstyle m-0">
              <li v-for="item in navigation_items" :key="item.to">
                <RouterLink @click="emits('categoryClick', item.to)" to="/">{{
                  item.label
                }}</RouterLink>
              </li>
              <li>
                <a class="btn btn-secondary-transparent" :href="mailto">
                  Contact
                </a>
              </li>
              <li class="menu-account">
                <RouterLink to="/admin">
                  <VaAvatar
                    text-color="success"
                    icon="person"
                    color="primary"
                  />
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="menu-box d-lg-none js-menu-open">
          <div class="menu-icon"></div>
        </div>
      </div>
    </div>
  </header>
</template>
