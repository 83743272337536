import { Episode } from "@/types/api";

export function parseDate(date?: Date) {
  if (!date) return "";
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
}

export function parseHour(hour?: Date) {
  if (!hour) return "";
  const h = ("0" + hour.getHours()).slice(-2);
  const m = ("0" + hour.getMinutes()).slice(-2);
  return `${h}:${m}`;
}

export function setHour(value: Array<number>) {
  if (!value) return;
  const date = new Date();
  date.setHours(value[0] + value[1]);
  date.setMinutes(value[3] + value[4]);
  return date;
}

export function dateFromHourString(hour: string, date: Date = new Date()) {
  const new_date = new Date(date.getTime());
  const values = hour.split(":");
  new_date.setHours(parseInt(values[0]));
  new_date.setMinutes(parseInt(values[1]));
  return new_date;
}

export function formatDateInput(date: Date) {
  const d = ("0" + date.getDate()).slice(-2);
  const m = ("0" + (date.getMonth() + 1)).slice(-2);
  return `${d}/${m}/${date.getFullYear()}`;
}

export function getDateString(input: string) {
  if (!input) return "";
  const date = new Date(input);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  return `${day}/${month}/${date.getFullYear()}`;
}

export function getEpisodeStartDate(episode: Episode): Date {
  return dateFromHourString(episode.startAt, new Date(episode.date));
}
