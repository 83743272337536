<template>
  <section id="formation">
    <div class="container">
      <div class="h2">
        <h2>Nos formations</h2>
        <CommonCircles />
      </div>
      <div class="card-list row">
        <CommonFormationCard
          v-for="(formation, key) in formations"
          :key="key"
          :id="key"
          :image="formation.image"
          :name="formation.name"
          :href="formation.href"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import CommonCircles from "../CommonCircles.vue";

import FormationImage1 from "@/assets/front/img/SGB01-1.jpeg";
import FormationImage2 from "@/assets/front/img/SGB02-1.jpeg";
import FormationImage3 from "@/assets/front/img/SGB03-1.jpeg";
import CommonFormationCard from "../CommonFormationCard.vue";

const formations = [
  {
    name: "Intervenant SmartGroup Formation qualifiante",
    href: "https://www.pretexte.com/cours/intervenant-smart-group-base-01-enjeu-personnel/",
    image: FormationImage1,
  },
  {
    name: "Intervenant SmartGroup Approfondissement niveau 1",
    href: "https://www.pretexte.com/cours/intervenant-smart-group-base-02-enjeu-interpersonnel/",
    image: FormationImage2,
  },
  {
    name: "Intervenant SmartGroup Approfondissement niveau 2",
    href: "https://www.pretexte.com/cours/intervenant-smart-group-base-03-enjeu-projet/",
    image: FormationImage3,
  },
];
</script>
