import { defineStore } from "pinia";
import front_css from "@/assets/front/css/theme.scss?inline";

type ApplicationState = {
  mode: "FRONT" | "ADMIN";
  style?: HTMLStyleElement;
};

/**
 * Application Store
 *
 * Mainly used to hack the website css to be properly loaded for each modes
 */
export const useApplication = defineStore("application", {
  state: (): ApplicationState => ({
    mode: "FRONT",
    style: undefined,
  }),
  getters: {
    getMode: (state) => state.mode,
  },
  actions: {
    setMode(mode: "ADMIN" | "FRONT") {
      this.mode = mode;
      if (mode === "FRONT") {
        if (!this.style) {
          const style = document.createElement("style");
          style.id = "front__stylesheet";
          style.textContent = front_css;
          this.style = document.head.appendChild(style);
        }
        this.style.textContent = front_css;
      } else {
        if (this.style) {
          this.style.textContent = "";
        }
      }
    },
  },
});
