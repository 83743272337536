<template>
  <div class="card-list exp-card-list slider-fade">
    <CommonOrganizationCard
      v-for="organization in public_api.home?.organizations"
      :key="organization.id"
      :id="organization.id"
      :name="organization.name"
      :description="organization.description"
      :logo="organization.logo"
    />
  </div>
  <div class="slider-fade-arrows exp-card-list-arrows">
    <div class="btn btn-primary arrow-prev">
      <img :src="ChevronLeftSVG" alt="chevron-left" class="js-svg" />
    </div>
    <div class="btn btn-primary arrow-next">
      <img :src="ChevronRightSVG" alt="chevron-right" class="js-svg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from "vue";
import $ from "jquery";
import ChevronLeftSVG from "@/assets/front/icon/chevron-left.svg";
import ChevronRightSVG from "@/assets/front/icon/chevron-right.svg";
import CommonOrganizationCard from "@/components/common/CommonOrganizationCard.vue";
import { useAPIPublic } from "@/store/api/public";
const public_api = useAPIPublic();

onMounted(() => {
  loadSliderExperiences();
});

onBeforeUnmount(() => {
  $(".exp-card-list").slick("unslick");
});

function loadSliderExperiences() {
  $(".exp-card-list").slick({
    infinite: true,
    arrows: true,
    speed: 0,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "linear",
    draggable: false,
    focusOnSelect: false,
    swipe: false,
    prevArrow: $(".exp-card-list-arrows .arrow-prev"),
    nextArrow: $(".exp-card-list-arrows .arrow-next"),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
}
</script>
