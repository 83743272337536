<template>
  <img :src="nsrc.href" :alt="alt" />
</template>

<script setup lang="ts">
const props = defineProps({
  path: {
    type: String,
    required: true,
  },
  alt: String,
});

const nsrc = new URL(`/src/assets/${props.path}`, import.meta.url);
</script>
