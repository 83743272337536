<template>
  <section id="partenaires">
    <div class="container">
      <div class="h2">
        <h2>Nos partenaires</h2>
        <CommonCircles />
      </div>
      <div class="partenaires-slider">
        <div
          v-for="(partner, key) in partners"
          :key="key"
          class="partenaires-slider-item"
        >
          <CommonImage :path="partner" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted } from "vue";
import $ from "jquery";
import CommonCircles from "../CommonCircles.vue";
import CommonImage from "../CommonImage.vue";

const partners = [
  "front/img/partenaire-adipso.png",
  "front/img/partenaire-pretexte.png",
  "front/img/partenaire-vapodil.png",
  "front/img/partenaire-adipso.png",
  "front/img/partenaire-pretexte.png",
  "front/img/partenaire-vapodil.png",
];

onBeforeUnmount(() => {
  $(".partenaires-slider").slick("unslick");
});

onMounted(() => {
  loadSliderPartenaires();
});

function loadSliderPartenaires() {
  $(".partenaires-slider").slick({
    infinite: true,
    arrows: false,
    slidesToShow: 5,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });
}
</script>
