import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useAPI } from "@/store/api/api";
import { useAPIUsers } from "@/store/api/user";
import { Roles } from "@/data/roles";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => {
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/organisation/:slug",
      name: "organization",
      component: () => import("@/views/OrganizationView.vue"),
    },
    {
      path: "/about",
      name: "about",
      component: () => import("../views/AboutView.vue"),
    },
    {
      path: "/mentions-legales",
      name: "legal-mention",
      component: () => import("../views/LegalMentionView.vue"),
    },
    {
      path: "/politique-confidentialite",
      name: "privacy-policy",
      component: () => import("../views/ConfidentialityPolicyView.vue"),
    },
    {
      path: "/reset-password/:token",
      name: "admin-reset",
      component: () => import("../views/admin/PasswordReset.vue"),
    },
    {
      path: "/create-account/:token",
      name: "admin-create",
      component: () => import("../views/admin/PasswordCreate.vue"),
    },
    {
      path: "/password-forgot",
      name: "password-forgot",
      component: () => import("../views/admin/PasswordForgot.vue"),
    },
    {
      path: "/connect",
      name: "admin-connect",
      beforeEnter: async () => {
        const api = useAPI();
        const is_authenticated = await api.isAuthenticated();
        if (is_authenticated) router.push("/admin");
      },
      component: () => import("../views/AuthView.vue"),
    },
    {
      path: "/admin",
      beforeEnter: async () => {
        const api = useAPI();
        const is_authenticated = await api.isAuthenticated();
        if (
          !is_authenticated &&
          router.currentRoute.value.name != "admin-auth"
        ) {
          router.push("/connect");
        }
      },
      component: () => import("../views/admin/AdminView.vue"),
      children: [
        {
          path: "account",
          name: "admin-account",
          component: () => import("@/views/admin/AccountView.vue"),
        },
        {
          path: "dashboard",
          name: "admin-dashboard",
          component: async () => {
            const user_api = useAPIUsers();
            await user_api.getCurrent();
            switch (user_api.getRole) {
              case Roles.ROLE_ADMIN:
                return import("@/views/admin/dashboard/DashboardAdmin.vue");
              case Roles.ROLE_SPEAKER:
              case Roles.ROLE_TRAINER:
                return import("@/views/admin/dashboard/DashboardSpeaker.vue");
              default:
                return import("@/views/admin/dashboard/DashboardMember.vue");
            }
          },
        },
        {
          path: "analytics",
          name: "admin-analytics",
          component: async () => {
            const user_api = useAPIUsers();
            await user_api.getCurrent();
            switch (user_api.getRole) {
              case Roles.ROLE_ADMIN:
                return import("@/views/admin/analytics/AnalyticsAdmin.vue");
              default:
                return import("@/views/admin/analytics/AnalyticsSpeaker.vue");
            }
          },
        },
        {
          path: "episodes",
          name: "admin-episodes",
          component: () => import("@/views/admin/crud/CrudEpisode.vue"),
        },
        {
          path: "organizations",
          name: "admin-organizations",
          component: () => import("@/views/admin/crud/CrudOrganization.vue"),
        },
        {
          path: "groups",
          name: "admin-groups",
          component: () => import("@/views/admin/crud/CrudGroup.vue"),
        },
        {
          path: "seasons",
          name: "admin-seasons",
          component: () => import("@/views/admin/crud/CrudSeason.vue"),
        },
        {
          path: "users",
          name: "admin-users",
          component: () => import("@/views/admin/crud/CrudUser.vue"),
        },
      ],
    },
  ],
});

export default router;
