<template>
  <FrontLayoutView v-if="public_api.home">
    <main class="home-main">
      <SectionIntroduction />
      <SectionExperience />
      <SectionIntervenants />
      <SectionFormations />
      <SectionPartenaires />
    </main>
  </FrontLayoutView>
</template>

<script setup lang="ts">
import "bootstrap/js/src/modal";
import "slick-carousel";
import $ from "jquery";

import FrontLayoutView from "./FrontLayoutView.vue";
import SectionIntroduction from "@/components/common/sections/SectionIntroduction.vue";
import SectionExperience from "@/components/common/sections/SectionExperience.vue";
import SectionIntervenants from "@/components/common/sections/SectionIntervenants.vue";
import SectionFormations from "@/components/common/sections/SectionFormations.vue";
import SectionPartenaires from "@/components/common/sections/SectionPartenaires.vue";
import SVGInject from "@iconfu/svg-inject";

import { useApplication } from "@/store/api/application";
import { useAPIPublic } from "@/store/api/public";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

const application_store = useApplication();
const public_api = useAPIPublic();
const route = useRoute();

onMounted(async () => {
  application_store.setMode("FRONT");
  await public_api.fetchHome();

  // SVG Inject
  SVGInject(document.getElementsByClassName("js-svg"));

  //Texte slider
  $(".text-slide-js").on("mouseenter", (e) => {
    $(".text-main-js").toggleClass("text-slide-mute");
    $(".text-main-js").toggleClass("text-slide-em");
    $(e.target).toggleClass("text-slide-mute");
    $(e.target).toggleClass("text-slide-em");
  });

  $(".text-slide-js").on("mouseleave", (e) => {
    $(e.target).toggleClass("text-slide-mute");
    $(e.target).toggleClass("text-slide-em");
    $(".text-main-js").toggleClass("text-slide-mute");
    $(".text-main-js").toggleClass("text-slide-em");
  });

  const hash = route.fullPath.split("#")[1];
  if (hash) {
    const target = $(`#${hash}`);
    const offset = target.offset();

    if (offset) {
      $("html, body").animate({ scrollTop: offset.top }, 500);
    }
  }
});
</script>
