<template>
  <div class="card card-special card-special-secondary-1">
    <div class="card-top">
      <div class="card-top-img">
        <img v-if="logo" :src="logo" :alt="name" />
        <img v-else :src="IconSVG" :alt="name" />
      </div>
      <div class="card-top-title">
        <p class="text-source h5 mb-1">Source</p>
        <p class="text-name h3 m-0">{{ name }}</p>
      </div>
    </div>
    <div class="card-body">
      <p>{{ description }}</p>
      <div class="text-center">
        <RouterLink
          :to="{
            name: 'organization',
            params: { slug: slugify(name) },
          }"
          class="btn-link"
          >En savoir plus</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconSVG from "@/assets/front/icon/icon-smartgroup.svg";

defineProps<{
  id: number;
  name: string;
  description?: string;
  logo?: string;
}>();

function slugify(str: string) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}
</script>
