<template>
  <section id="intro">
    <img :src="MaskTopSVG" class="intro-bg d-none d-lg-block" />
    <img :src="LogoOutlineSVG" class="intro-logo-outlined logo-outlined" />
    <div class="row h-100 m-0">
      <div class="intro-slider-wrapper">
        <div>
          <div class="intro-slider">
            <p
              v-for="item in slider_elements"
              :key="item"
              class="intro-slider-text"
            >
              {{ item }}
            </p>
          </div>
          <div class="intro-link">
            <span class="video-player-icon d-flex js-vid-open">
              <img :src="PlaySVG" class="js-svg" />
            </span>
            <a href="#" class="btn btn-primary btn-slide js-vid-open">
              En savoir plus
            </a>
          </div>
        </div>
      </div>
      <div
        id="home-top-image"
        class="offset-xl-6 col-xl-6 offset-lg-7 col-lg-5 offset-md-6 col-md-6 col-12 p-0 d-none d-lg-block"
      >
        <CommonImage
          :path="`front/img/home-top-${random_number}.jpg`"
          class="intro-img-vid js-vid-open"
          alt="Smart Group"
        />
      </div>
    </div>
    <div class="intro-vid d-none">
      <div class="d-flex flex-column align-items-end">
        <div class="close-box js-vid-close">
          <div class="close-icon"></div>
        </div>
        <video controls class="video-bernard">
          <source :src="IntroductionVideo" type="video/mp4" />
        </video>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import MaskTopSVG from "@/assets/front/icon/mask-top-hp.svg";
import LogoOutlineSVG from "@/assets/front/icon/logo-outline.svg";
import PlaySVG from "@/assets/front/icon/play.svg";

import IntroductionVideo from "@/assets/front/video/vid.mp4";
import $ from "jquery";
import CommonImage from "../CommonImage.vue";

const random_number = Math.floor(Math.random() * 7 + 1);

const slider_elements = [
  "Libre",
  "Intuitif",
  "Vivant",
  "Expérimental",
  "Rythmique",
  "Contributif",
  "Ouvert",
  "Incluant",
  "Ressourçant",
  "Dynamique",
  "Proactif",
  "Attracteur",
];

onMounted(() => {
  // Slider vidéo
  $(".intro-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    arrows: false,
    dots: false,
  });

  //Popup Video
  $(".js-vid-open").on("click", () => {
    $(".intro-vid").toggleClass("d-none");
    $(".intro-vid").animate({ opacity: 1 }, 200);
    $("body").toggleClass("modal-open");
  });

  $(".js-vid-close").on("click", () => {
    const video = $(".video-bernard").get(0) as HTMLVideoElement;
    if (video) {
      video.pause();
    }

    $(".intro-vid").animate(
      {
        opacity: 0,
      },
      200,
      function () {
        $(".intro-vid").toggleClass("d-none");
      }
    );
    $("body").toggleClass("modal-open");
  });
});
</script>
