<template>
  <svg
    width="364px"
    height="86px"
    viewBox="0 0 364 86"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>logo/smart-group-V2</title>
    <defs>
      <polygon
        id="path-1"
        points="0 0 24.6972 0 24.6972 24.697 0 24.697"
      ></polygon>
      <polygon
        id="path-3"
        points="0 0 24.6972 0 24.6972 24.697 0 24.697"
      ></polygon>
    </defs>
    <g
      id="logo/smart-group-V2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-31">
        <path
          d="M16.167,37.6209 C25.095,37.6209 32.333,30.6849 32.333,22.1299 C32.333,13.5739 25.095,6.6389 16.167,6.6389 C7.238,6.6389 0,13.5739 0,22.1299 C0,30.6849 7.238,37.6209 16.167,37.6209"
          id="Fill-1"
          fill="#B3CCA5"
        ></path>
        <path
          d="M44.5489,19.0316 C49.2419,19.0316 53.0469,15.2266 53.0469,10.5336 C53.0469,5.8396 49.2419,2.0356 44.5489,2.0356 C39.8549,2.0356 36.0509,5.8396 36.0509,10.5336 C36.0509,15.2266 39.8549,19.0316 44.5489,19.0316"
          id="Fill-3"
          fill="#E85D1D"
        ></path>
        <path
          d="M33.2183,81.9686 C37.9113,81.9686 41.7163,78.1646 41.7163,73.4716 C41.7163,68.7796 37.9113,64.9726 33.2183,64.9726 C28.5253,64.9726 24.7203,68.7796 24.7203,73.4716 C24.7203,78.1646 28.5253,81.9686 33.2183,81.9686"
          id="Fill-5"
          fill="#E9BE00"
        ></path>
        <path
          d="M63.1929,85.5096 C72.1219,85.5096 79.3589,78.3086 79.3589,69.4246 C79.3589,60.5396 72.1219,53.3386 63.1929,53.3386 C54.2639,53.3386 47.0279,60.5396 47.0279,69.4246 C47.0279,78.3086 54.2639,85.5096 63.1929,85.5096"
          id="Fill-7"
          fill="#51769B"
        ></path>
        <path
          d="M74.0259,26.556 C81.3589,26.556 87.3039,20.612 87.3039,13.278 C87.3039,5.945 81.3589,0 74.0259,0 C66.6929,0 60.7469,5.945 60.7469,13.278 C60.7469,20.612 66.6929,26.556 74.0259,26.556"
          id="Fill-9"
          fill="#0BAFAE"
        ></path>
        <g id="Group-13" transform="translate(73.140800, 30.584600)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <g id="Clip-12"></g>
          <path
            d="M12.348,24.697 C19.169,24.697 24.698,19.168 24.698,12.347 C24.698,5.528 19.169,0 12.348,0 C5.529,0 0,5.528 0,12.347 C0,19.168 5.529,24.697 12.348,24.697"
            id="Fill-11"
            fill="#97B513"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          d="M12.8145,67.6287 C19.6345,67.6287 25.1635,62.1017 25.1635,55.2807 C25.1635,48.4607 19.6345,42.9327 12.8145,42.9327 C5.9945,42.9327 0.4645,48.4607 0.4645,55.2807 C0.4645,62.1017 5.9945,67.6287 12.8145,67.6287"
          id="Fill-14"
          fill="#3FB1DA"
        ></path>
        <path
          d="M16.167,37.6209 C25.095,37.6209 32.333,30.6849 32.333,22.1299 C32.333,13.5739 25.095,6.6389 16.167,6.6389 C7.238,6.6389 0,13.5739 0,22.1299 C0,30.6849 7.238,37.6209 16.167,37.6209"
          id="Fill-16"
          fill="#A2DCB4"
        ></path>
        <path
          d="M44.5489,19.0316 C49.2419,19.0316 53.0469,15.2266 53.0469,10.5336 C53.0469,5.8396 49.2419,2.0356 44.5489,2.0356 C39.8549,2.0356 36.0509,5.8396 36.0509,10.5336 C36.0509,15.2266 39.8549,19.0316 44.5489,19.0316"
          id="Fill-18"
          fill="#E85D1D"
        ></path>
        <path
          d="M33.2183,81.9686 C37.9113,81.9686 41.7163,78.1646 41.7163,73.4716 C41.7163,68.7796 37.9113,64.9726 33.2183,64.9726 C28.5253,64.9726 24.7203,68.7796 24.7203,73.4716 C24.7203,78.1646 28.5253,81.9686 33.2183,81.9686"
          id="Fill-20"
          fill="#F9D202"
        ></path>
        <path
          d="M63.1929,85.5096 C72.1219,85.5096 79.3589,78.3086 79.3589,69.4246 C79.3589,60.5396 72.1219,53.3386 63.1929,53.3386 C54.2639,53.3386 47.0279,60.5396 47.0279,69.4246 C47.0279,78.3086 54.2639,85.5096 63.1929,85.5096"
          id="Fill-22"
          fill="#101288"
          opacity="0.400000006"
        ></path>
        <path
          d="M74.0259,26.556 C81.3589,26.556 87.3039,20.612 87.3039,13.278 C87.3039,5.945 81.3589,0 74.0259,0 C66.6929,0 60.7469,5.945 60.7469,13.278 C60.7469,20.612 66.6929,26.556 74.0259,26.556"
          id="Fill-24"
          fill="#31B9BF"
        ></path>
        <g id="Group-28" transform="translate(73.140800, 30.584600)">
          <mask id="mask-4" fill="white">
            <use xlink:href="#path-3"></use>
          </mask>
          <g id="Clip-27"></g>
          <path
            d="M12.348,24.697 C19.169,24.697 24.698,19.168 24.698,12.347 C24.698,5.528 19.169,0 12.348,0 C5.529,0 0,5.528 0,12.347 C0,19.168 5.529,24.697 12.348,24.697"
            id="Fill-26"
            fill="#97B513"
            mask="url(#mask-4)"
          ></path>
        </g>
        <path
          d="M12.8145,67.6287 C19.6345,67.6287 25.1635,62.1017 25.1635,55.2807 C25.1635,48.4607 19.6345,42.9327 12.8145,42.9327 C5.9945,42.9327 0.4645,48.4607 0.4645,55.2807 C0.4645,62.1017 5.9945,67.6287 12.8145,67.6287"
          id="Fill-29"
          fill="#3FB1DA"
        ></path>
      </g>
      <g id="Group-43" transform="translate(119.000000, 26.000000)">
        <path
          d="M10.7202051,30.5680809 C7.07770775,30.5680809 3.4771471,29.5126664 0,26.999379 L1.71820432,24.5264561 C4.70529174,26.6764625 8.01948501,27.8921481 10.801682,27.8921481 C14.5256562,27.8921481 17.9213264,26.3108071 17.9213264,22.5402824 C17.9213264,19.9462658 16.2438605,18.9324031 14.0343983,18.1619149 C12.2742574,17.5540721 9.32910658,16.9450421 7.65044252,16.4179284 C3.76351448,15.2022429 1.59479076,12.4455809 1.59479076,8.8365144 C1.59479076,4.25632409 5.1965496,0.729173896 11.1287878,0.729173896 C13.9924617,0.729173896 16.7351184,1.37738124 20.0900501,3.32437764 L18.3718458,5.79730051 C15.5477122,4.29668864 13.6246174,3.40510676 10.8831589,3.40510676 C7.20112131,3.40510676 4.70529174,5.39128052 4.70529174,8.8365144 C4.70529174,11.3913536 6.62838654,12.8100491 8.6736967,13.4998082 C10.4745761,14.107651 13.4197269,14.7154938 15.098391,15.2426074 C18.985319,16.458293 21.0306292,18.8516739 21.0306292,22.4191887 C21.0306292,27.2427536 17.1437011,30.5680809 10.7202051,30.5680809"
          id="Fill-1"
          fill="#101288"
        ></path>
        <path
          d="M10.7202051,30.5680809 C7.07770775,30.5680809 3.4771471,29.5126664 0,26.999379 L1.71820432,24.5264561 C4.70529174,26.6764625 8.01948501,27.8921481 10.801682,27.8921481 C14.5256562,27.8921481 17.9213264,26.3108071 17.9213264,22.5402824 C17.9213264,19.9462658 16.2438605,18.9324031 14.0343983,18.1619149 C12.2742574,17.5540721 9.32910658,16.9450421 7.65044252,16.4179284 C3.76351448,15.2022429 1.59479076,12.4455809 1.59479076,8.8365144 C1.59479076,4.25632409 5.1965496,0.729173896 11.1287878,0.729173896 C13.9924617,0.729173896 16.7351184,1.37738124 20.0900501,3.32437764 L18.3718458,5.79730051 C15.5477122,4.29668864 13.6246174,3.40510676 10.8831589,3.40510676 C7.20112131,3.40510676 4.70529174,5.39128052 4.70529174,8.8365144 C4.70529174,11.3913536 6.62838654,12.8100491 8.6736967,13.4998082 C10.4745761,14.107651 13.4197269,14.7154938 15.098391,15.2426074 C18.985319,16.458293 21.0306292,18.8516739 21.0306292,22.4191887 C21.0306292,27.2427536 17.1437011,30.5680809 10.7202051,30.5680809 Z"
          id="Stroke-3"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M52.3339394,29.9998904 L52.3339394,17.7563697 C52.3339394,14.5936878 51.2699468,12.1623167 47.7916015,12.1623167 C43.6195043,12.1623167 42.3098827,14.5129587 42.3098827,17.5949115 L42.3098827,29.9998904 L39.2808586,29.9998904 L39.2808586,17.7563697 C39.2808586,14.6352395 38.1761276,12.2026812 34.6989805,12.2026812 C30.525685,12.2026812 29.2568019,14.5129587 29.2568019,17.6352761 L29.2568019,29.9998904 L26.228976,29.9998904 L26.228976,9.72975836 L28.7248056,9.72975836 L29.2975404,12.0400358 C30.7701158,10.1345911 32.7758857,9.52674837 35.1890401,9.52674837 C39.0771664,9.52674837 40.4682648,11.6743804 41.1224765,12.810524 C41.9408402,11.5936513 44.1910409,9.52674837 47.9150151,9.52674837 C52.7844588,9.52674837 55.3617653,12.3237749 55.3617653,16.3376742 L55.3617653,29.9998904 L52.3339394,29.9998904 Z"
          id="Fill-5"
          fill="#101288"
        ></path>
        <path
          d="M52.3339394,29.9998904 L52.3339394,17.7563697 C52.3339394,14.5936878 51.2699468,12.1623167 47.7916015,12.1623167 C43.6195043,12.1623167 42.3098827,14.5129587 42.3098827,17.5949115 L42.3098827,29.9998904 L39.2808586,29.9998904 L39.2808586,17.7563697 C39.2808586,14.6352395 38.1761276,12.2026812 34.6989805,12.2026812 C30.525685,12.2026812 29.2568019,14.5129587 29.2568019,17.6352761 L29.2568019,29.9998904 L26.228976,29.9998904 L26.228976,9.72975836 L28.7248056,9.72975836 L29.2975404,12.0400358 C30.7701158,10.1345911 32.7758857,9.52674837 35.1890401,9.52674837 C39.0771664,9.52674837 40.4682648,11.6743804 41.1224765,12.810524 C41.9408402,11.5936513 44.1910409,9.52674837 47.9150151,9.52674837 C52.7844588,9.52674837 55.3617653,12.3237749 55.3617653,16.3376742 L55.3617653,29.9998904 L52.3339394,29.9998904 Z"
          id="Stroke-7"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M74.3091017,20.431709 L68.7435096,20.431709 C65.1441471,20.431709 63.6296351,21.9726854 63.6296351,24.2425983 C63.6296351,26.5944275 65.471253,27.9739457 68.6212942,27.9739457 C70.544389,27.9739457 72.0169644,27.7293839 73.0821552,26.5944275 C73.8178438,25.8239393 74.3091017,24.9323574 74.3091017,23.1076419 L74.3091017,20.431709 Z M77.213514,30.0408486 C75.1274654,30.0408486 74.4313171,28.6625176 74.2683632,28.2564976 C73.2858475,29.5540995 71.1590604,30.6083268 68.2534499,30.6083268 C63.6703736,30.6083268 60.5610708,28.1745813 60.5610708,24.6486183 C60.5610708,21.243749 62.9742252,17.8365053 68.7435096,17.8365053 L74.3091017,17.8365053 L74.3091017,15.8918833 C74.3091017,13.7026995 72.6723743,12.2020876 68.9484001,12.2020876 C66.9030899,12.2020876 65.4293163,12.5261913 64.2431083,13.7834286 L61.5831267,12.2840039 C63.711112,10.2159139 66.1662031,9.52615477 68.8669232,9.52615477 C74.3905786,9.52615477 77.2961891,11.5526931 77.2961891,16.0533415 L77.2961891,26.6359793 C77.2961891,28.419143 78.1552913,28.3775913 78.563874,28.4595076 L78.563874,30.0408486 L77.213514,30.0408486 Z"
          id="Fill-9"
          fill="#101288"
        ></path>
        <path
          d="M74.3091017,20.431709 L68.7435096,20.431709 C65.1441471,20.431709 63.6296351,21.9726854 63.6296351,24.2425983 C63.6296351,26.5944275 65.471253,27.9739457 68.6212942,27.9739457 C70.544389,27.9739457 72.0169644,27.7293839 73.0821552,26.5944275 C73.8178438,25.8239393 74.3091017,24.9323574 74.3091017,23.1076419 L74.3091017,20.431709 Z M77.213514,30.0408486 C75.1274654,30.0408486 74.4313171,28.6625176 74.2683632,28.2564976 C73.2858475,29.5540995 71.1590604,30.6083268 68.2534499,30.6083268 C63.6703736,30.6083268 60.5610708,28.1745813 60.5610708,24.6486183 C60.5610708,21.243749 62.9742252,17.8365053 68.7435096,17.8365053 L74.3091017,17.8365053 L74.3091017,15.8918833 C74.3091017,13.7026995 72.6723743,12.2020876 68.9484001,12.2020876 C66.9030899,12.2020876 65.4293163,12.5261913 64.2431083,13.7834286 L61.5831267,12.2840039 C63.711112,10.2159139 66.1662031,9.52615477 68.8669232,9.52615477 C74.3905786,9.52615477 77.2961891,11.5526931 77.2961891,16.0533415 L77.2961891,26.6359793 C77.2961891,28.419143 78.1552913,28.3775913 78.563874,28.4595076 L78.563874,30.0408486 L77.213514,30.0408486 Z"
          id="Stroke-11"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M93.091687,12.3242498 C89.5318648,12.3242498 87.5680315,14.5537981 87.5680315,17.3923764 L87.5680315,30.0003653 L84.5402056,30.0003653 L84.5402056,9.72904604 L87.0360352,9.72904604 L87.6495084,12.2435207 C89.1220838,10.3368888 91.495698,9.52722325 93.7458986,9.52722325 C94.5654605,9.52722325 95.4245627,9.60795237 96.2009898,9.72904604 L96.2009898,12.7290826 C95.2196722,12.4465307 94.2371565,12.3242498 93.091687,12.3242498"
          id="Fill-13"
          fill="#101288"
        ></path>
        <path
          d="M93.091687,12.3242498 C89.5318648,12.3242498 87.5680315,14.5537981 87.5680315,17.3923764 L87.5680315,30.0003653 L84.5402056,30.0003653 L84.5402056,9.72904604 L87.0360352,9.72904604 L87.6495084,12.2435207 C89.1220838,10.3368888 91.495698,9.52722325 93.7458986,9.52722325 C94.5654605,9.52722325 95.4245627,9.60795237 96.2009898,9.72904604 L96.2009898,12.7290826 C95.2196722,12.4465307 94.2371565,12.3242498 93.091687,12.3242498 Z"
          id="Stroke-15"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M106.881894,29.9998904 C103.935545,29.9998904 102.135864,28.3378203 102.135864,25.0540447 L102.135864,12.2430458 L99.3524688,12.2430458 L99.3524688,9.72975836 L102.135864,9.72975836 L102.135864,3.48512356 L105.16369,2.79655166 L105.16369,9.72975836 L108.928403,9.72975836 L108.928403,12.2430458 L105.16369,12.2430458 L105.16369,24.8913992 C105.16369,26.7161148 105.982054,27.3239576 107.536106,27.3239576 L108.967943,27.3239576 L108.967943,29.9998904 L106.881894,29.9998904 Z"
          id="Fill-17"
          fill="#101288"
        ></path>
        <path
          d="M106.881894,29.9998904 C103.935545,29.9998904 102.135864,28.3378203 102.135864,25.0540447 L102.135864,12.2430458 L99.3524688,12.2430458 L99.3524688,9.72975836 L102.135864,9.72975836 L102.135864,3.48512356 L105.16369,2.79655166 L105.16369,9.72975836 L108.928403,9.72975836 L108.928403,12.2430458 L105.16369,12.2430458 L105.16369,24.8913992 C105.16369,26.7161148 105.982054,27.3239576 107.536106,27.3239576 L108.967943,27.3239576 L108.967943,29.9998904 L106.881894,29.9998904 Z"
          id="Stroke-19"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M137.367919,30.6085642 C132.702168,30.6085642 129.348434,28.0133605 127.956137,25.4988859 C127.015558,23.838003 126.402085,22.1343811 126.402085,15.5668298 C126.402085,9.16192398 127.057495,7.53903125 127.956137,5.87814834 C129.306497,3.36486092 132.825581,0.729292616 137.490134,0.729292616 C142.359578,0.729292616 145.960139,3.32449636 147.432714,7.90468667 L144.037044,7.90468667 C142.890376,4.86309839 140.51796,3.40522548 137.408658,3.40522548 C135.035043,3.40522548 133.111949,4.21607825 131.598635,5.9185129 C129.348434,8.43180033 129.511388,10.134235 129.511388,15.5668298 C129.511388,22.053652 130.248275,23.7157221 131.598635,25.4585213 C132.784843,26.9591332 134.994305,27.9326314 137.367919,27.9326314 C142.605207,27.9326314 144.814669,24.1621066 144.814669,21.5680901 L144.814669,17.3915454 L137.244506,17.3915454 L137.244506,14.7156125 L147.801757,14.7156125 L147.801757,20.3915819 C147.801757,24.810314 145.060298,30.6085642 137.367919,30.6085642"
          id="Fill-21"
          fill="#101288"
        ></path>
        <path
          d="M137.367919,30.6085642 C132.702168,30.6085642 129.348434,28.0133605 127.956137,25.4988859 C127.015558,23.838003 126.402085,22.1343811 126.402085,15.5668298 C126.402085,9.16192398 127.057495,7.53903125 127.956137,5.87814834 C129.306497,3.36486092 132.825581,0.729292616 137.490134,0.729292616 C142.359578,0.729292616 145.960139,3.32449636 147.432714,7.90468667 L144.037044,7.90468667 C142.890376,4.86309839 140.51796,3.40522548 137.408658,3.40522548 C135.035043,3.40522548 133.111949,4.21607825 131.598635,5.9185129 C129.348434,8.43180033 129.511388,10.134235 129.511388,15.5668298 C129.511388,22.053652 130.248275,23.7157221 131.598635,25.4585213 C132.784843,26.9591332 134.994305,27.9326314 137.367919,27.9326314 C142.605207,27.9326314 144.814669,24.1621066 144.814669,21.5680901 L144.814669,17.3915454 L137.244506,17.3915454 L137.244506,14.7156125 L147.801757,14.7156125 L147.801757,20.3915819 C147.801757,24.810314 145.060298,30.6085642 137.367919,30.6085642 Z"
          id="Stroke-23"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M163.065857,12.3242498 C159.504837,12.3242498 157.542202,14.5537981 157.542202,17.3923764 L157.542202,30.0003653 L154.513178,30.0003653 L154.513178,9.72904604 L157.009007,9.72904604 L157.623679,12.2435207 C159.096254,10.3368888 161.469868,9.52722325 163.720069,9.52722325 C164.538433,9.52722325 165.397535,9.60795237 166.17516,9.72904604 L166.17516,12.7290826 C165.192644,12.4465307 164.211327,12.3242498 163.065857,12.3242498"
          id="Fill-25"
          fill="#101288"
        ></path>
        <path
          d="M163.065857,12.3242498 C159.504837,12.3242498 157.542202,14.5537981 157.542202,17.3923764 L157.542202,30.0003653 L154.513178,30.0003653 L154.513178,9.72904604 L157.009007,9.72904604 L157.623679,12.2435207 C159.096254,10.3368888 161.469868,9.52722325 163.720069,9.52722325 C164.538433,9.52722325 165.397535,9.60795237 166.17516,9.72904604 L166.17516,12.7290826 C165.192644,12.4465307 164.211327,12.3242498 163.065857,12.3242498 Z"
          id="Stroke-27"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M183.442035,18.2021607 C183.442035,15.5665924 181.968262,12.1617231 177.754228,12.1617231 C173.499455,12.1617231 172.02688,15.5665924 172.02688,18.2021607 L172.02688,21.9726854 C172.02688,24.566702 173.499455,27.9323939 177.713489,27.9323939 C181.968262,27.9323939 183.442035,24.566702 183.442035,21.9726854 L183.442035,18.2021607 Z M177.713489,30.6083268 C172.884784,30.6083268 168.997856,27.4456448 168.997856,22.01305 L168.997856,18.1617961 C168.997856,12.6876495 172.884784,9.52615477 177.754228,9.52615477 C182.582933,9.52615477 186.469861,12.6876495 186.469861,18.1617961 L186.469861,22.01305 C186.469861,27.4456448 182.582933,30.6083268 177.713489,30.6083268 L177.713489,30.6083268 Z"
          id="Fill-29"
          fill="#101288"
        ></path>
        <path
          d="M183.442035,18.2021607 C183.442035,15.5665924 181.968262,12.1617231 177.754228,12.1617231 C173.499455,12.1617231 172.02688,15.5665924 172.02688,18.2021607 L172.02688,21.9726854 C172.02688,24.566702 173.499455,27.9323939 177.713489,27.9323939 C181.968262,27.9323939 183.442035,24.566702 183.442035,21.9726854 L183.442035,18.2021607 Z M177.713489,30.6083268 C172.884784,30.6083268 168.997856,27.4456448 168.997856,22.01305 L168.997856,18.1617961 C168.997856,12.6876495 172.884784,9.52615477 177.754228,9.52615477 C182.582933,9.52615477 186.469861,12.6876495 186.469861,18.1617961 L186.469861,22.01305 C186.469861,27.4456448 182.582933,30.6083268 177.713489,30.6083268 Z"
          id="Stroke-31"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M200.587653,30.6085642 C195.431842,30.6085642 192.239864,28.0133605 192.239864,23.1482439 L192.239864,9.7294022 L195.26769,9.7294022 L195.26769,21.8102774 C195.26769,25.1759694 196.005775,27.9729959 200.587653,27.9729959 C205.129991,27.9729959 205.824942,25.1759694 205.824942,21.8102774 L205.824942,9.7294022 L208.852767,9.7294022 L208.852767,23.1482439 C208.852767,28.0133605 205.702726,30.6085642 200.587653,30.6085642"
          id="Fill-33"
          fill="#101288"
        ></path>
        <path
          d="M200.587653,30.6085642 C195.431842,30.6085642 192.239864,28.0133605 192.239864,23.1482439 L192.239864,9.7294022 L195.26769,9.7294022 L195.26769,21.8102774 C195.26769,25.1759694 196.005775,27.9729959 200.587653,27.9729959 C205.129991,27.9729959 205.824942,25.1759694 205.824942,21.8102774 L205.824942,9.7294022 L208.852767,9.7294022 L208.852767,23.1482439 C208.852767,28.0133605 205.702726,30.6085642 200.587653,30.6085642 Z"
          id="Stroke-35"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M229.805275,17.1888915 C229.805275,15.9316542 228.985713,12.1623167 224.853156,12.1623167 C220.842814,12.1623167 219.002394,14.5533232 219.002394,17.5545469 L219.002394,22.5811218 C219.002394,25.2974192 220.842814,27.9733521 224.853156,27.9733521 C228.985713,27.9733521 229.805275,24.1220982 229.805275,22.8636737 L229.805275,17.1888915 Z M225.549304,30.6089204 C223.094213,30.6089204 221.047705,30.0806195 219.043133,28.0137166 L219.043133,39 L216.015307,39 L216.015307,9.72975836 L218.511137,9.72975836 L219.125808,12.0400358 C221.006966,9.8912166 223.094213,9.52674837 225.549304,9.52674837 C228.373438,9.52674837 232.831902,11.7966612 232.831902,17.0666107 L232.831902,22.94559 C232.831902,28.2155394 228.290763,30.6089204 225.549304,30.6089204 L225.549304,30.6089204 Z"
          id="Fill-37"
          fill="#101288"
        ></path>
        <path
          d="M229.805275,17.1888915 C229.805275,15.9316542 228.985713,12.1623167 224.853156,12.1623167 C220.842814,12.1623167 219.002394,14.5533232 219.002394,17.5545469 L219.002394,22.5811218 C219.002394,25.2974192 220.842814,27.9733521 224.853156,27.9733521 C228.985713,27.9733521 229.805275,24.1220982 229.805275,22.8636737 L229.805275,17.1888915 Z M225.549304,30.6089204 C223.094213,30.6089204 221.047705,30.0806195 219.043133,28.0137166 L219.043133,39 L216.015307,39 L216.015307,9.72975836 L218.511137,9.72975836 L219.125808,12.0400358 C221.006966,9.8912166 223.094213,9.52674837 225.549304,9.52674837 C228.373438,9.52674837 232.831902,11.7966612 232.831902,17.0666107 L232.831902,22.94559 C232.831902,28.2155394 228.290763,30.6089204 225.549304,30.6089204 Z"
          id="Stroke-39"
          stroke="#101288"
          stroke-width="0.648"
        ></path>
        <path
          d="M240.316276,2.87656846 C240.186871,2.83382952 240.04189,2.83382952 239.884927,2.83382952 L238.303317,2.83382952 L238.303317,4.98383591 L239.913684,4.98383591 C240.645778,4.98383591 241.135838,4.64192435 241.135838,3.97353473 C241.135838,3.37518949 240.877029,3.0047853 240.316276,2.87656846 L240.316276,2.87656846 Z M241.149018,8.30085295 L239.755523,5.68190535 L238.303317,5.68190535 L238.303317,8.30085295 L237.528088,8.30085295 L237.528088,2.13694727 L239.827414,2.13694727 C241.03519,2.13694727 241.925445,2.74953882 241.925445,4.00084017 C241.925445,4.47215576 241.767284,4.7843875 241.437781,5.12629906 C241.220909,5.36848642 240.947722,5.49670326 240.602643,5.59642746 L242.069228,8.30085295 L241.149018,8.30085295 Z M239.683631,0.99724206 C237.312414,0.99724206 235.372544,2.90506109 235.372544,5.26876221 C235.372544,7.61821702 237.312414,9.52722325 239.683631,9.52722325 C242.069228,9.52722325 243.994719,7.61821702 243.994719,5.26876221 C243.994719,2.90506109 242.069228,0.99724206 239.683631,0.99724206 L239.683631,0.99724206 Z M239.683631,10.5363372 C236.751661,10.5363372 234.366065,8.17382331 234.366065,5.26876221 C234.366065,2.36370112 236.751661,0 239.683631,0 C242.615602,0 245,2.36370112 245,5.26876221 C245,8.17382331 242.615602,10.5363372 239.683631,10.5363372 L239.683631,10.5363372 Z"
          id="Fill-41"
          fill="#101288"
        ></path>
      </g>
    </g>
  </svg>
</template>
