import type {
  APIPostCreateAccountContract,
  APIPostResetPasswordContract,
  APIPostValidateCertificationContract,
  APIResponseCreateAccount,
  APIResponseGeneric,
  URLParameter,
  User,
} from "@/types/api";
import axios from "axios";
import { defineStore } from "pinia";
import {
  fetchAPI,
  fetchAPIMembers,
  postAPIData,
  sendAPIData,
  useAPI,
} from "./api";
import { generateCRUDActions, stringify_parameters } from "./crud";
import { Roles, getHighestRole } from "@/data/roles";

const unknown_user: User = {
  id: 1,
  firstname: "unknown",
  isActive: false,
  pendingCertifications: [],
  validCertifications: [],
  hasOrganization: false,
};

type ResetPasswordResponse = {
  isSuccess: boolean;
  message?: string;
};

type UserStoreState = {
  logged_user?: User;
  user?: User;
  users: User[];
  memberscount: number;
  fetching: boolean;
};

export async function is_current_user_admin(): Promise<boolean> {
  const api = useAPIUsers();
  if (!api.user) api.getCurrent();
  const role = api.logged_user?.roles?.includes("ROLE_ADMIN");
  if (!role) return false;
  return role;
}

export async function getCurrentUser() {
  const user_api = useAPIUsers();
  return await user_api.getCurrent();
}

export async function getCurrentUserString() {
  const user_api = useAPIUsers();
  const user = await user_api.getCurrent();
  return `/users/${user.id}`;
}

export const useAPIUsers = defineStore("/users", {
  state: (): UserStoreState => ({
    logged_user: undefined,
    user: undefined,
    users: [],
    memberscount: 0,
    fetching: false,
  }),
  getters: {
    getRole: (state): Roles => {
      if (state.logged_user && state.logged_user?.roles)
        return getHighestRole(state.logged_user.roles);
      return Roles.ROLE_USER;
    },
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    isFetching: (state) => state.fetching,
  },
  actions: {
    ...generateCRUDActions<User>("/users"),
    async fetchAll(params?: Array<URLParameter>): Promise<void> {
      this.fetching = true;
      const response = await fetchAPIMembers<User[]>(
        "/users",
        stringify_parameters(params)
      );

      this.memberscount = (response as any).membersCount;
      this.users = response;
      this.fetching = false;
    },
    async fetch(id: number) {
      this.fetching = true;
      const response = await fetchAPI<User>(`/users/${id}`);
      this.user = response;
      this.fetching = false;
    },
    async addOneIfNotExist(uri: string) {
      const id = parseInt(uri.split("/")[2]);
      if (isNaN(id)) return;

      if (!this.users.find((user) => user.id === id)) {
        const response = await fetchAPI<User>(`/users/${id}`);
        if (response) {
          this.users.push(response);
        }
      }
    },
    async fetchSearch(query: string): Promise<void> {
      this.fetching = true;

      const firstname_param = [{ key: "firstname", value: query }];
      const firstname_search = await fetchAPIMembers<User[]>(
        "/users",
        stringify_parameters(firstname_param)
      );

      const lastname_param = [{ key: "lastname", value: query }];
      const lastname_search = await fetchAPIMembers<User[]>(
        "/users",
        stringify_parameters(lastname_param)
      );

      this.users = firstname_search.concat(lastname_search);
      this.fetching = false;
    },
    async fetchSpeakers(): Promise<void> {
      this.fetching = true;

      const response = await fetchAPI<User[]>("/speakers");
      this.users = response;
      this.fetching = false;
    },
    async getCurrent(): Promise<User> {
      if (this.logged_user) {
        return this.logged_user;
      }

      return new Promise((resolve, reject) => {
        try {
          const api = useAPI();
          if (!api.getToken) reject("User not logged in.");

          const id = api.user_id;
          if (!id) {
            resolve(unknown_user);
            return;
          }

          const api_url = import.meta.env.VITE_API_URL;
          axios
            .get(`${api_url}/users/${id}`, {
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: `Bearer ${api.getToken}`,
              },
            })
            .then((response) => {
              this.logged_user = response.data;
              resolve(response.data as unknown as User);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    async resetPassword() {
      const user = await getCurrentUser();
      if (!user) {
        return;
      }

      const api_url = import.meta.env.VITE_API_URL;
      return axios.post(`${api_url}/reset-password`, {
        email: user.email,
      });
    },

    async sendNewPassword(
      token: string,
      password: string
    ): Promise<ResetPasswordResponse> {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.post<ResetPasswordResponse>(
        `${api_url}/reset-password/reset/${token}`,
        {
          new_password: password,
        }
      );
      return response.data;
    },

    async createAccount(token: string, contract: APIPostCreateAccountContract) {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.post<APIResponseCreateAccount>(
        `${api_url}/create-account/${token}`,
        contract
      );
      return response.data;
    },
    async resetPasswordByEmail(contract: APIPostResetPasswordContract) {
      const api_url = import.meta.env.VITE_API_URL;
      const response = await axios.post<ResetPasswordResponse>(
        `${api_url}/reset-password`,
        contract
      );
      return response.data;
    },
    validate_certification(contract: APIPostValidateCertificationContract) {
      return postAPIData<
        APIPostValidateCertificationContract,
        APIResponseGeneric
      >(contract, "/validate-certification");
    },
    add(user: User) {
      this.users.push(user);
    },
    update(id: number, user: User) {
      const index = this.users.findIndex((e) => e.id == id);
      this.users[index] = user;
    },
    remove(id: number) {
      const index = this.users.findIndex((e) => e.id == id);
      this.users.splice(index, 1);
    },
    async postAvatar(file: File, id: number) {
      const api_url = import.meta.env.VITE_API_URL;
      const form = new FormData();
      form.append("avatar", file);
      const api = useAPI();

      const response = await axios.post(`${api_url}/users/${id}/avatar`, form, {
        headers: {
          Authorization: `Bearer ${api.getToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (this.logged_user && this.logged_user.id == id) {
        this.logged_user.avatar = response.data.filePath;
      }
      return response.data.filePath;
    },
    async deleteAvatar(id: number) {
      const api_url = import.meta.env.VITE_API_URL;
      const api = useAPI();
      axios.delete(`${api_url}/users/${id}/avatar`, {
        headers: {
          Authorization: `Bearer ${api.getToken}`,
        },
      });
    },
  },
});
